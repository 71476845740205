<template>
    <div class="imageContainer">
        <Image :img="imgbg"
               sizes="(max-width: 400px) 200vw, (max-width: 711px) 1000px, 100vw " />
        <Image :img="imgfg"
               class="fg"
               sizes="(max-width: 1300px) 100vw, 1000px " />

    </div>
</template>

<script>
export default {
    props: ["imgbg", "imgfg"]
}
</script>

<style lang="scss" scoped>
.imageContainer {
    width: 100%;
    position: relative;
    margin-bottom: 90px;
    background-color: black;
    overflow: hidden;
    // padding-bottom: 56.25%;


    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        object-fit: cover;

        &.fg {
            z-index: 2;
            object-fit: contain;
        }
    }
}

.imageContainer {

    height: 711px;
}


@media screen and (max-width: $breakpoint) {
    .imageContainer {
        margin-bottom: 55px;
    }
}


@media screen and (max-width: 1300px) {
    .imageContainer {
        padding-bottom: 56.25%;
        height: 0;
    }
}

// @media screen and (max-width: 711px) {
//     .imageContainer {
//         padding-bottom: 0;
//         height: 400px;
//     }
// }

// @media screen and (max-width: 400px) {
//     .imageContainer {
//         padding-bottom: 100%;
//         height: 0;
//     }
// }
</style>