<template>
    <GreyContainer specialclass="specialAnimationGreyContainer">

        <div class="before">
            <slot name="before"></slot>
        </div>
        <div class="grid">
            <div v-for="animation in animations"
                 class="animationContainer">
                <Animation :animation="animation" />
            </div>
        </div>
        <div class="after">
            <slot name="after"></slot>
        </div>
    </GreyContainer>
</template>

<script>
import GreyContainer from "./GreyContainer.vue"
import Animation from "./Animation.vue"
export default {
    props: ["animations"],
    components: { GreyContainer, Animation }
}
</script>
<style lang="scss">
.specialAnimationGreyContainer {
    padding: 87px 121px 145px 121px !important;
    margin-bottom: 146px;
    position: relative;

    .before {
        position: absolute;
        top: 25px;
        left: 0;
        // transform: translateY(-50%);
        width: 100%;
    }

    .after {
        position: absolute;
        bottom: 25px;
        left: 0;
        // transform: translateY(-50%);
        width: 100%;
    }
}


@media screen and (max-width: 1024px) {
    .specialAnimationGreyContainer {
        padding: 87px 20px 145px 20px !important;
    }

}
</style>
<style lang="scss" scoped>
.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 80px;
    // margin-bottom: 77px;
    width: 100%;
}

.animationContainer {
    min-width: 0px;
}

@media screen and (max-width: 1024px) {
    .grid {
        grid-template-columns: 1fr;
        gap: 24px;
        margin-bottom: 47px;
    }

    .container {
        margin-bottom: 77px;
    }
}
</style>