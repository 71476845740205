<template>
    <div class="linkContainer">
        <div class="imageContainer">
            <Image :img="link.logo_image" />
        </div>
        <p v-html="nl2br(link.support_text)"></p>
        <!-- {{ link }} -->

        <!-- <template v-if="link.support_link_select == 'faq'">
            <router-link></router-link>
        </template>
        <template v-else> -->
        <div class="aCont">
            <a :href="link.support_url">{{ link.support_link_text }}</a>
        </div>
        <!-- </template> -->
        <!-- {{ link }} -->
    </div>
</template>

<style lang="scss" scoped>
.linkContainer {
    background-color: #F2F2F2;
    border-radius: 20px;
    padding: 10px 15px 40px 15px;
    display: flex;
    flex-direction: column;
    min-width: 0px;

    .imageContainer {
        width: 100%;
        aspect-ratio: 1 / 1;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    p {
        flex-grow: 2;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        // padding: 0px 30px;
        box-sizing: border-box;
        width: 300px;
        max-width: 100%;
        margin: 0px auto;
        margin-bottom: 56px;
    }

    .aCont {
        width: 100%;
        box-sizing: border-box;
    }

    a {
        background-color: $volt;
        border-radius: 3px;
        height: 33px;
        width: 100%;
        max-width: 300px;
        display: inline-block;
        line-height: 33px;
        font-weight: 500;
        text-align: center;
        display: block;
        margin: 0px auto;
        font-size: 16px;

        &:hover {
            background-color: $voltHover;
        }
    }
}


@media screen and (max-width: $breakpoint) {
    .linkContainer {
        padding: 10px 0px 25px 0px;
        max-width: 100%;

        .imageContainer {
            width: 100%;
            max-width: 400px;
            margin: 0px auto;
        }

        p {
            text-align: center;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            min-width: 0px;
            padding: 0px 30px;
        }

        .aCont {
            padding: 0px 15px;
        }

        a {
            width: 300px;
            max-width: 100%;
            margin: 0px auto;
        }
    }


}
</style>
<script>
import GreenLink from '../general/GreenLink.vue';

export default {
    props: ["link"],
    components: { GreenLink }
}
</script>