<template>
    <div id="cont">
        <div id="container">
            <LinkComponent v-for="link in links"
                           :key="link.support_link_select"
                           :link="link" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
div#cont {
    width: 100%;
    padding: 0px 10px;
    box-sizing: border-box;
}

#container {
    width: 100%;
    max-width: $max-width+100px;
    margin: 0px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    margin-bottom: 130px;
    box-sizing: border-box;
}

@media screen and (max-width: $breakpoint) {
    #container {
        grid-template-columns: 1fr;
    }
}

// .greyContainer {
//     background-color: #F2F2F2;
//     border-radius: 20px;
//     max-width: $max-width+100px;
//     margin: 0px auto;
//     padding: 25px;
//     box-sizing: border-box;

//     .content {
//         max-width: $max-width;
//         margin: 0px auto;
//     }
// }
</style>
<script>
import LinkComponent from "./Link.vue"
export default {
    props: ["links"],
    components: { LinkComponent }
}
</script>