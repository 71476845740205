<template>
    <div class="testAppsContainer">

        <padder big
                style="height:100%">
            <div class="verticalAligner">
                <h2>{{ general.app_links_text }}</h2>
                <div class="appButtons">
                    <a href="https://apps.apple.com/app/id1630335934">
                        <img :src="ios.sizes[0].src">
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=ch.siresca.app">
                        <img :src="play.sizes[0].src">
                    </a>
                </div>
            </div>
        </padder>
    </div>
</template>
<script>
export default {
    props: ["ios", "play"]
}
</script>
<style lang="scss" scoped>
h2 {
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: center;
}

.testAppsContainer {
    background-color: $altBlack;
    width: 100%;
    color: white;
    height: 500px;
    margin-bottom: 160px;
}

.verticalAligner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.appButtons {
    display: flex;
    justify-content: center;
    gap: 47px;
}

@media screen and (max-width: $breakpoint) {
    .testAppsContainer {

        margin-bottom: 96px;
        height: 380px;
    }

    .appButtons {
        flex-direction: column;
        text-align: center;
        gap: 13px;

        img {
            height: 72px;
            width: auto;
        }
    }
}
</style>