<template>
    <div>
        <TextPart :pretitle="agb_page.pre_title"
                  :title="agb_page.long_title"
                  :text="agb_page.main_content" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    mounted() {
    },
    computed: {
        ...mapState(["agb"]),
        agb_page() {
            return this.agb[this.currLocale];
        },

    }

}
</script>