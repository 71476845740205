<template>
    <div>
        <TextPart :pretitle="datenschutz_page.pre_title"
                  :title="datenschutz_page.long_title"
                  :text="datenschutz_page.main_content" />
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
export default {
    mounted() {
    },
    computed: {
        ...mapState(["datenschutz"]),
        datenschutz_page() {
            return this.datenschutz[this.currLocale]
        }
    }

}
</script>
  