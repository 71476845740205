<template>
    <div>
        <TextPart :pretitle="notfound_page.main_text_pretitle"
                  :title="notfound_page.main_text_title"
                  :text="notfound_page.main_text_text" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    mounted() {
    },
    computed: {
        ...mapState(["notfound"]),
        notfound_page() {
            return this.notfound[this.currLocale];
        },
    }

}
</script>