<template>
    <padder big>
        <div class="cont">
            <h2>{{ title }}</h2>
            <div v-html="nl2br(text)"
                 class="text"></div>
        </div>
    </padder>
</template>


<script>
export default {
    props: ["title", "text"]
}
</script>

<style scoped lang="scss">
h2 {
    text-align: center;
}

.text {
    font-weight: 500;
    max-width: $reduced-width;
    margin: 0px auto;
    @include bigFontSize;
}

.cont {
    margin-top: 146px;
    margin-bottom: 130px;
}

@media screen and (max-width: $breakpoint) {

    .text {
        @include defaultFontSize;
    }
}
</style>