<template>
    <form id="contactForm">
        <div>
            Anrede
        </div>
        <div>
            <select>
                <option></option>
            </select>
        </div>
        <div>
            Vorname
        </div>
        <div>
            <input type="text"
                   name="first_name"
                   id="first_name">
        </div>
        <div>
            Name*
        </div>
        <div>
            <input type="text"
                   name="first_name"
                   id="first_name">
        </div>
        <div>
            Firma*
        </div>
        <div>
            <input type="text"
                   name="first_name"
                   id="first_name">
        </div>
        <div>
            E-Mail*
        </div>
        <div>
            <input type="text"
                   name="first_name"
                   id="first_name">
        </div>
        <div>
            Telefon*
        </div>
        <div>
            <input type="text"
                   name="first_name"
                   id="first_name">
        </div>
        <div>
            Nachricht*
        </div>
        <div>
            <input type="text"
                   name="first_name"
                   id="first_name">
        </div>
        <div>

        </div>
        <div>
            <input type="submit"
                   value="Absenden">
        </div>
    </form>
</template>

<style lang="scss">
#contactForm {
    @include defaultFontSize;
    font-weight: 500;
    display: grid;
    grid-template-columns: 100px 300px;
    row-gap: 8px;

    div {
        height: 34px;
        line-height: 34px;
    }
}

input, select {
    width: 100%;
    height: 34px;
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: "Swiss Intl";
    @include defaultFontSize;

    &[type=submit] {
        border: unset;
        background-color: $volt;
        font-weight: 500;

        &:hover {
            background-color: $voltHover;
        }
    }
}</style>