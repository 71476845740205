<template>
    <GreyContainer>
        <carousel :items-to-show="1"
                  :wrapAround="true"
                  :autoplay="2000"
                  :transition="600"
                  class="testimonials">
            <slide v-for="test in testimonials"
                   :key="test">
                <div class="testimonialsContainer">
                    <div class="portraitdesktop">
                        <Image :img="test.image"
                               sizes="300px" />
                    </div>
                    <div class="textContainer">
                        <div class="quote">
                            «{{ test.testimonial }}»
                        </div>
                        <div class="portraitmobile">
                            <Image :img="test.image" />
                        </div>
                        <div class="person"
                             v-html="nl2br(test.person)"></div>
                    </div>
                </div>
            </slide>
            <template #addons>
                <!-- <navigation /> -->
                <pagination />
            </template>
        </carousel>
    </GreyContainer>
</template>


<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
export default {
    props: ["testimonials"],
    components: {
        Carousel,
        Slide,
        Pagination, Navigation
    }
}
</script>

<style lang="scss">
.carousel__pagination-button::after {
    width: 12px;
    height: 12px;
    border-radius: 6px;
}

.carousel__pagination {
    padding-left: 0px;
}

.carousel__pagination-button::after {

    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 300ms;
}

.carousel__pagination-button--active::after {

    background-color: rgb(0, 0, 0);
}

.testimonialsContainer {
    display: grid;
    grid-template-columns: 254px 1fr;
    text-align: left;
    padding: 50px;
    width: 100%;
}

.portraitdesktop {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    position: relative;
    overflow: hidden;
    min-width: none;
    flex-shrink: 0;
    margin-right: 54px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.portraitmobile {
    display: none;
}

.quote {
    @include titleFontSize;
    font-weight: 500;
    margin-bottom: 15px;
}

.person {
    line-height: 1.2;
}


@media screen and (max-width: 900px) {
    .testimonialsContainer {
        display: block;
        text-align: center;
    }

    .portraitdesktop {
        display: none;
    }


    .portraitmobile {
        display: inline-block;
        border-radius: 50%;
        width: 200px;
        height: 200px;
        // position: relative;
        overflow: hidden;
        margin-bottom: 8px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}


@media screen and (max-width: $breakpoint) {
    .testimonialsContainer {
        .quote {
            @include bigFontSize;
            margin-bottom: 49px;
            
        }
    }


    .portraitmobile {
        width: 80px;
        height: 80px;
        overflow: hidden;
    }
}
</style>