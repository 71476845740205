<template>
    <div id="partners">
        <padder big>
            <div id="partnergrid">
                <div v-for="partner in partners"
                     class="partner">
                    <div class="photoContainer">
                        <Image :img="partner.image"
                               sizes="250px" />
                    </div>
                    <div class="textContainer">
                        <div class="name">{{ partner.name }}</div>
                        <div class="role">{{ partner.role }}</div>

                        <div class="background"
                             v-html="nl2br(partner.person)" />
                    </div>
                </div>
            </div>

        </padder>
    </div>
</template>

<script>
export default {
    props: ["partners"]
}
</script>


<style lang="scss" scoped>
#partners {
    width: 950px;
    max-width: 100%;
    margin: 0px auto;
    margin-top: 71px;
}

.partner {
    display: grid;
    grid-template-columns: 210px 1fr;
    gap: 20px;
    @include defaultFontSize;
}

#partnergrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 20px;
}

.name {
    @include bigFontSize;
    font-weight: 500;
}

.role {
    margin-bottom: 1em;
}


.photoContainer {
    width: 210px;
    height: 210px;
    overflow: hidden;
    border-radius: 20px;
    border: 0px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media screen and (max-width: $breakpoint) {
    #partnergrid {
        grid-template-columns: 1fr;
    }

    .partner {
        font-size: 1.4rem;
        grid-template-columns: 180px 1fr;

        .photoContainer {
            width: 180px;
            height: 180px;
        }
    }

    .name {
        @include defaultFontSize;
    }

}
</style>