<template>
    <!-- <SubNavigation :sections="sections" /> -->
    <div id="contact"
         v-if="isNaN(contact_page)">
        <anchor hash="contactacnhor" />
        <TextPart :pretitle="contact_page.contact_pretitle"
                  :title="contact_page.contact_title"
                  :text="contact_page.contact_text" />

        <padder big>
            <!-- <ContactForm /> -->

            <anchor hash="address" />
            <!-- <h4>{{ contact.address_title }}</h4> -->
            <div v-html="nl2br(contact_page.address)"
                 class="address inlineText"></div>
        </padder>

    </div>
    <NewsletterSignup />
</template>

<script>
import ContactForm from '../components/contact/ContactForm.vue';
import { mapState } from 'vuex';
export default {
    components: { ContactForm },
    mounted() {
    },
    data() {
        return {
            sections: [
                {
                    ref: "contactanchor",
                    text: "Kontaktformular"
                },
                {
                    ref: "address",
                    text: "Adresse"
                }
            ],
        }
    },
    computed: {
        ...mapState(["contact"]),
        contact_page() {
            return this.contact[this.currLocale]
        }
    }

}
</script>

<style lang="scss"
       scoped>
    #contact {
        width: $reduced-width;
        margin: 0px auto;
        flex-grow: 2;
        max-width: 100%;
    }

    h4 {
        @include bigFontSize;
        font-weight: 500;
    }

    .address {
        @include bigFontSize;
        margin-bottom: 54px;
        font-weight: 500;
        // margin-bottom: 250px;
    }

    @media screen and (max-width: $breakpoint) {
        .address {
            @include defaultFontSize;
        }
    }
</style>