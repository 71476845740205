<template>
    <div class="circlePadder">
        <div class="circle">
            <Image :img="animation.image"
                   sizes="300px" />
        </div>
    </div>

    <div class="text">{{ animation.text }}</div>
</template>
<script>
export default {
    props: ["animation"]
}
</script>

<style lang="scss" scoped>
.circlePadder {
    width: 100%;
    max-width: 300px;
    margin: 0px auto;
}

.circle {
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
    height: 0px;
    border-radius: 50%;
    margin-bottom: 22px;
    position: relative;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.text {
    text-align: center;
    font-weight: 500;
    max-width: 500px;
    margin: 0px auto;
}


@media screen and (max-width: $breakpoint) {
    .circlePadder {
        // padding: 0px 85px;
    }
}
</style>