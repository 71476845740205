<template>
    <div>
        <TextPart :pretitle="support_page.support_pre_title"
                  :title="support_page.support_title"
                  :text="''" />
        <!-- {{ support }} -->
        <LinkRepeater :links="support_page.support_links" />
        <NewsletterSignup />
    </div>
</template>

<script>
import NewsletterSignup from '../components/general/NewsletterSignup.vue';
import LinkRepeater from '../components/support/LinkRepeater.vue';
import { mapState } from 'vuex';
export default {
    components: { NewsletterSignup, LinkRepeater },
    mounted() {
    },
    computed: {
        ...mapState(["support"]),
        support_page() {
            return this.support[this.currLocale]
        },
    }

}
</script>