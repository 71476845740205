<template>
    <padder big>
        <div class="makeItEasyContainer">
            <h2>{{ home.intro_text }}</h2>
            <div class="linksContainer">
                <div to="/about"
                     v-for="role in home.role_links"
                     class="roleContainer">
                    <div class="imgCont">
                        <img src="../../assets/icons/role-active.svg">
                    </div>
                    <span>
                        <span class="centrer">
                            {{ role.title }}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </padder>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: mapState(["home"])
}
</script>

<style lang="scss" scoped>
.makeItEasyContainer {
    margin-bottom: 135px;
}

h2 {
    text-align: center;
    max-width: 1100px;
    margin: 0px auto;
    margin-bottom: 36px;
}

.linksContainer {
    text-align: center;
    @include bigFontSize;
    font-weight: 500;

    .roleContainer {
        display: inline-flex;
        height: 64px;

        span {
            line-height: 64px;
            display: inline-block;
        }

        img {
            height: 64px;
            width: auto;
            margin-right: 19px;
        }

        &:first-child {
            margin-right: 47px;
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .makeItEasyContainer {
        margin-bottom: 73px;
    }


    .linksContainer {
        .roleContainer {
            width: 100%;
            display: grid;
            grid-template-columns: 61px 1fr 61px;
            text-align: center;
            margin-bottom: 14px;
            height: 40px;

            span {
                line-height: 40px;

                .centrer {
                    line-height: 1em;
                }
            }

            img {
                height: 40px;
                margin-right: 0px;
            }
        }
    }
}

@media screen and (max-width: 400px) {

    .linksContainer {

        // .roleContainer {
        //     display: flex;
        //     gap: 10px;
        //     justify-content: left;
        //     span {
        //         text-align: left;
        //     }
        // }
        .roleContainer {
            grid-template-columns: 45px 1fr 45px;
            height: 30px;
            margin-bottom: 30px;

            span {
                @include defaultFontSize;
                line-height: 30px;
            }

            .imgCont {}

            img {
                height: 30px;
                margin-right: 0px;
            }
        }
    }
}
</style>