<template>
    <div class="fullContainer">
        <padder big
                style="height:100%">
            <div class="collaboratorsContainer">

                <div class="titleContainer">
                    <span>{{ title }}</span>
                </div>
                <div class="collaborators">
                    <template v-for="collaborator in collaborators">
                        <div>
                            <Image :img="collaborator" sizes="400px"/>
                        </div>
                    </template>
                </div>
            </div>
        </padder>
    </div>
</template>

<script>
export default {
    props: ["title", "collaborators"]
}
</script>

<style lang="scss">
.fullContainer {
    width: $max-width;
    max-width: 100%;
    margin: 0px auto;
}
.titleContainer {
    font-weight: 500;
    text-align: center;
    @include bigFontSize;
    margin-bottom: 55px;
}

.collaboratorsContainer {
    margin-bottom: 200px;
    overflow: hidden;
}

.collaborators {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 100px;
    text-align: center;

    img {
        height: 80px;
        width: auto;
        max-width: 80vw;
    }
}

@media screen and (max-width: $breakpoint) {
    .collaborators {
        flex-direction: column;
        gap: 40px;

        img {
            width: 100%;
            object-fit: contain;
        }
    }
}
</style>