<template>
  <SubNavigation :sections="generateSections" />
  <anchor hash="abos" />
  <div id="pricing" class="navPadding">
    <div id="header_image">
      <div id="img_cont">
        <Image :img="pricing_page.intro_image" sizes="500px" />
        <a class="testthirtydays" href="https://admin.siresca.com/"><span class="textCenterer">{{
    pricing_page.text_on_image }}</span></a>
      </div>
    </div>
    <PricingSchemes />
    <anchor hash="register" />
    <Register />
  </div>
</template>

<script>
import PricingSchemes from "@/components/pricing/PricingSchemes"
import { mapState } from 'vuex';
export default {
  components: { PricingSchemes },
  mounted() {
  },
  computed: {
    ...mapState(["pricing"]),
    pricing_page() {
      return this.pricing[this.currLocale]
    },
    generateSections() {
      return [
        { ref: "abos", text: this.pricing_page.header_1 },
        { ref: "register", text: this.pricing_page.header_2 }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
#pricing {
  margin-top: 50px;
}

#header_image {
  width: 1200px;
  max-width: 100%;
  // margin: 0px auto;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0px auto 110px auto;

  #img_cont {
    margin: 0px auto;
    position: relative;
    min-width: 0;
  }

  img {
    width: 600px;
    max-width: 100%;
    height: auto;
  }
}

.testthirtydays {
  width: 126px;
  height: 126px;
  border-radius: 50%;
  position: absolute;
  top: 20%;
  left: 0;
  transform: translateX(-20%);
  background-color: $volt;
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;

  @media screen and (max-width: 750px) {
    transform: translateX(10px);
  }


  .textCenterer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;

    &>span {
      display: block;
      font-weight: 400;
    }
  }


  .highlightsub {
    font-size: 1rem;
  }
}
</style>
