<template>
  <SubNavigation :sections="generateSections" />
  <div class="vorteile navPadding">
    <anchor hash="electro_installers" />
    <TextPart :pretitle="vorteile_page.intro_pretitle"
              :title="vorteile_page.intro_title"
              :text="vorteile_page.intro_text" />
    <AnimationContainer :animations="vorteile_page.intro_animations"
                        class="firstAnimations">
    </AnimationContainer>
    <anchor hash="project_leaders"
            style="margin-bottom: 20px" />
    <TextPart :pretitle="vorteile_page.second_pretitle"
              :title="vorteile_page.second_title"
              :text="vorteile_page.second_text" />
    <AnimationContainer :animations="vorteile_page.second_animations">
    </AnimationContainer>
    <Register />
  </div>
</template>

<script>
import AnimationContainer from '../components/general/AnimationContainer.vue';
import { mapState } from "vuex"
export default {
  components: { AnimationContainer },
  mounted() {
  },
  computed: {
    ...mapState(["vorteile"]),
    vorteile_page() {
      return this.vorteile[this.currLocale]
    },
    tempanims() {
      return []
    },
    generateSections() {
      return [
        {
          ref: "electro_installers",
          text: this.vorteile_page.header_1
        },
        {
          ref: "project_leaders",
          text: this.vorteile_page.header_2
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.vorteile {
  @include defaultFontSize;
}

.firstAnimations {
  margin-bottom: 157px;
}

@media screen and (max-width: $breakpoint) {
  .firstAnimations {
    margin-bottom: 100px;
  }

}
</style>
