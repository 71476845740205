<template>
    <GreyContainer specialclass="greynopad">
        <h4>{{ home_page.extra_info_title }}</h4>
        <div class="imageContainer">
            <Image :img="home_page.extra_desktop_image"
                   class="desktop" />
            <Image :img="home_page.extra_mobile_image"
                   class="mobile" />
        </div>

        <div class="listsContainer">
            <ul class="left">
                <li v-for="p in home_page.extra_info_list_admin">
                    <ReplaceLinks :htmlContent="p.text" />
                </li>
            </ul>
            <ul class="right">
                <li v-for="p in home_page.extra_info_list_mobile">
                    <ReplaceLinks :htmlContent="p.text" />
                </li>
            </ul>
        </div>
    </GreyContainer>
</template>

<script>
import GreyContainer from "../general/GreyContainer.vue"
import { mapState } from "vuex";
import ReplaceLinks from "../general/ReplaceLinks.vue";
export default {
    components: { GreyContainer, ReplaceLinks },
    computed: {
        ...mapState(["home"]),
        home_page() {
            return this.home[this.currLocale]
        }
    }
}
</script>

<style lang="scss">
.greynopad {
    padding-bottom: 50px !important;
    margin-bottom: 200px !important;
}



@media screen and (max-width: $breakpoint) {
    .greynopad {
        padding-bottom: 50px !important;
        margin-bottom: 50px !important;
    }

}
</style>

<style lang="scss" scoped>
h4 {
    text-align: center;
}

.imageContainer {
    width: 100%;
    // max-width: 800px;
    margin: 0px auto;

    img {
        width: 100%;
        height: auto;

        &.mobile {
            display: none;
        }

    }
}

.listsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 80px;
    padding: 0px 20px;
}

ul {
    font-weight: 500;
    margin: 0px;
    padding: 0px;

    li {
        margin-bottom: 0.5em;
    }
}


@media screen and (max-width: $breakpoint) {
    .imageContainer {
        margin-bottom: 30px;

        img {
            &.desktop {
                display: none;
            }

            &.mobile {
                display: block;
            }
        }
    }

    .listsContainer {
        display: block;
    }
}
</style>