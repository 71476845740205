<template>
    <div class="impressumPage">
        <TextPart :pretitle="ìmpressum_page.pre_title"
                  :title="ìmpressum_page.long_title"
                  :text="ìmpressum_page.main_content" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    mounted() {
    },
    computed: {
        ...mapState(["impressum"]),
        impressum_page() {
            return this.impressum[this.currLocale]
        }
    }

}
</script>


<style lang="scss">
.impressumPage {
    margin-bottom: 137px;

    .text {
        font-weight: 400 !important;
        @include defaultFontSize;

        p {
            margin: 0px;
        }
    }

    h4 {
        @include bigFontSize;
        font-weight: 500;
        margin: 0px;
        margin-top: 67px;
        margin-bottom: 10px;
    }
}
</style>