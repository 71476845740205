<template>
    <li :class="{ open: selected }">
        <button class="mainLine"
                @click="activate(!selected)">
            <div>{{ row.question }}</div>
            <div class="arrow"><img src="../../assets/icons/arrow.svg" /></div>
        </button>
        <div class="contentHider"
             :style="genStyle">
            <div class="contentPart">
                <div class="greyBar" />
                <div class="content inlineText"
                     ref="content"
                     v-html="row.answer" />
            </div>

        </div>
    </li>
</template>

<script>
export default {
    props: ["row"],
    data() {
        return {
            contentHeight: 0,
            selected: false,
            justOpened: false
        }
    },
    computed: {
        genStyle() {
            return {
                height: this.contentHeight + "px"
            }
        },
    },
    methods: {
        activate(yes) {
            if (!this.justOpened) {
                this.selected = yes;
                if (this.selected) {
                    this.contentHeight = this.$refs.content.getBoundingClientRect().height + 60;
                    this.justOpened = true;
                    this.$emit("closeAll")
                }
                else {
                    this.contentHeight = 0;
                }
            }
            else {
                this.justOpened = false
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.mainLine {
    all: unset;
    cursor: pointer;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 20px;
    font-weight: 500;
    @include bigFontSize;
    padding: 10px 0px;

    .arrow {
        text-align: center;
        line-height: 100%;
        position: relative;

        img {
            transform: translateY(-50%) rotate(0deg);
            position: absolute;
            right: 0px;
            top: 50%;
            display: block;
            transition: transform 400ms;
        }
    }
}


@media screen and (min-width: $breakpoint) {
    .mainLine {
        padding: 18px 0px;
    }
}

li.open img {
    transform: translateY(-50%) rotate(-180deg) !important;
}

.contentHider {
    height: 0px;
    overflow: hidden;
    transition: height 400ms;
}


.contentPart {
    display: grid;
    grid-template-columns: 9px 1fr;
    gap: 20px;
    @include defaultFontSize;
    padding-top: 20px;
    padding-bottom: 40px;

    .greyBar {
        background-color: #f2f2f2;
        border-radius: 10px;
    }
}
@media screen and (max-width: $breakpoint) {
    .mainLine {
        @include defaultFontSize;
    }
}

</style>